<template>
  <div class="background">
    <b-container class="component">
      <b-jumbotron class="product-jumbotron">
        <template #header>{{ $t("nature.jumbotron.title") }}</template>
        <template #lead>
          <p>
            {{ $t("nature.jumbotron.lead") }}
          </p>
        </template>
        <b-button variant="info" to="/contact" class="contact">
          {{ $t("nature.jumbotron.contact") }}
        </b-button>
      </b-jumbotron>
      <br>
      <b-row>
        <b-col>
          <p>
            {{ $t("nature.text1") }}
          </p>
          <p>
            {{ $t("nature.text2") }}
          </p>
          <p>
            {{ $t("nature.text3") }}
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <SlideToCompare :leftImage=luchtfoto leftLabel="Luchtfoto" :rightImage=zeekweekkaart rightLabel="Zeekweekkaart" />
      </b-row>
      <br>
      <b-row id="kweldermonitoring">
        <b-col>
          <h3>{{ $t("nature.saltmarsh.title" ) }}</h3>
          <p>
            {{ $t("nature.saltmarsh.text1") }}
            <!-- <a href="https://destaatvangroningen.nl/natuurmonitor-kwelders-zeekweek.html"
            target="_blank">beeld.</a> -->
          </p>
          <!-- <Quote name="Edwin Hooft" :imgUrl=edwin
        quote="Eerst wat ik depressief, maar dankzij Spheer.ai zie ik weer het licht."
        subscript1="20-2-2023, Leeuwarden, Nederland" subscript2="Ecoloog Provincie Fryslân" /> -->
          <p>
            {{ $t("nature.saltmarsh.text2") }}
          </p>
          <p>
            {{ $t("nature.saltmarsh.text3") }}
          </p>
          <p>
            {{ $t("nature.saltmarsh.listtitle") }}
          <ul>
            <li>{{ $t("nature.saltmarsh.listitem1") }}</li>
            <li>{{ $t("nature.saltmarsh.listitem2") }}</li>
            <li>{{ $t("nature.saltmarsh.listitem3") }}</li>
            <li v-html="$t('nature.saltmarsh.listitem4')"></li>
          </ul>
          </p>
        </b-col>
      </b-row>
      <br>
      <b-row id="heidevergrassing">
        <b-col>
          <h3>{{ $t("nature.heather.title") }}</h3>
          <p>
            {{ $t("nature.heather.text1") }}
          </p>
          <!-- <Quote name="Willem Molenaar" :imgUrl=willem
        quote="Omdat deze methodiek per jaar een indruk geeft, kan je sneller een oordeel vellen en hoef je niet te wachten op de nieuwe kartering."
        subscript1="20-2-2023, Leeuwarden, Nederland" subscript2="Ecoloog Provincie Fryslân" /> -->
          <p>
            {{ $t("nature.heather.text2") }}
          </p>
          <p>
            {{ $t("nature.heather.text3") }}
          </p>
          <p>
            {{ $t("nature.heather.text4") }}
          </p>
          <p>
            {{ $t("nature.heather.listtitle") }}
          <ul>
            <li>{{ $t("nature.heather.listitem1") }}</li>
            <li>{{ $t("nature.heather.listitem2") }}</li>
            <li>{{ $t("nature.heather.listitem3") }}</li>
            <li>{{ $t("nature.heather.listitem4") }}</li>
            <li>{{ $t("nature.heather.listitem5") }}</li>
          </ul>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
// import Quote from "./Quote.vue"
import SlideToCompare from "./SlideToCompare.vue"
import Willem from "@/assets/placeholder.jpg"
import Edwin from "@/assets/placeholder2.jpg"
import Luchtfoto from "@/assets/luchtfoto.jpg"
import Zeekweekkaart from "@/assets/zeekweekkaart.jpg"

export default {
  name: "Natuurmonitoring",
  components: { SlideToCompare },
  methods: {},
  data: function () {
    return {
      willem: Willem,
      edwin: Edwin,
      luchtfoto: Luchtfoto,
      zeekweekkaart: Zeekweekkaart
    }
  }
}
</script>
<style>
.product-jumbotron {
  overflow: hidden;
  background: rgb(255, 209, 224);
  background: linear-gradient(45deg, rgba(255, 62, 124, 0.2) 0%, rgba(32, 183, 174, 0.2) 33%, rgba(116, 198, 175, 0.2) 66%, rgba(217, 215, 168, 0.2) 100%);
}

.product-jumbotron h1 {
  font-size: 2.5rem;
}

@media (min-width: 768px) {
  .product-jumbotron h1 {
    font-size: 4.5rem;
  }
}

/* .slide-to-compare {
    max-height: 240px;
} */

.slide-to-compare .right-image {
  object-fit: cover;
  max-height: 14rem;
  /* position: absolute;
    top: 0;
    height: 100%; */
}

.contact {
  font-size: 1.25rem;
  float: right;
  clear: both;
  /* Is margin right een goede manier om dit te doen @Mark? */
  margin-right: 5rem;
}
</style>
