<template>
  <div>
    <b-form @submit="onSubmit" @reset="onReset" v-if="showForm">
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group id="input-group-1" :label="$t('email-signup.email')" label-for="input-1">
            <b-form-input id="input-1" v-model="form.email" type="email" required></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2*" :label="$t('email-signup.name')" label-for="input-2">
            <b-form-input id="input-2" v-model="form.name" type="text" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group id="input-group-3" :label="$t('tel')" label-for="input-3">
            <b-form-input id="input-3" v-model="form.telephone" type="tel"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-4" :label="$t('email-signup.org')" label-for="input-4">
            <b-form-input id="input-4" v-model="form.organisation" type="text"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group id="input-group-5" :label="$t('email-signup.msg')" label-for="input-5">
        <b-form-textarea id="textarea-default" required v-model="form.message"></b-form-textarea>
        <b-form-text>{{ $t('email-signup.note') }}</b-form-text>
      </b-form-group>

      <b-button type="submit" variant="info">{{ $t('email-signup.send') }}</b-button>
      <b-button type="reset" variant="danger">{{ $t('email-signup.delete') }}</b-button>
    </b-form>
    <b-modal id="confirmation" :title="confirmationTitle" ref="confirmation" ok-only ok-variant="info"
      title-class="confirmation-title">
      <div class="confirmation-message">{{ confirmationMessage }}</div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EmailSignup",
  data() {
    return {
      form: {
        email: "",
        name: "",
        telephone: "",
        organisation: "",
        message: "",
      },
      showForm: true,
      confirmationTitle: "",
      confirmationMessage: "",
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      const path = "https://fqzp7bk7bb.execute-api.eu-central-1.amazonaws.com/api/contact-form";
      axios
        .post(path, this.form)
        .then(() => {
          this.confirmationTitle = this.$t("email-signup.confirmation.title");
          this.confirmationMessage = this.$t("email-signup.confirmation.msg");
          this.$refs["confirmation"].show()
        })
        .catch((error) => {
          this.confirmationTitle = this.$t("email-signup.error.title");
          this.confirmationMessage = error + "\n\n" + this.$t("email-signup.error.msg");
          this.$refs["confirmation"].show()
        });
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.telephone = "";
      this.form.organisation = "";
      this.form.message = "";
      // Trick to reset/clear native browser form validation state
      this.showForm = false;
      this.$nextTick(() => {
        this.showForm = true;
      });
    }
  },
};
</script>

<style scoped>
.btn {
  margin-right: 1em;
}

.form-control {
  border: 2px solid var(--theme-color-one-light);
}

.confirmation-message {
  white-space: pre-wrap;
}
</style>
