<template>
  <div class="background">
    <b-container class="component">
      <h1>Ons Team</h1>
      <b-row align-h="around">
        <b-col cols="12" class="mb-4">
          <b-card no-body>
            <b-row no-gutters align-v="center">
              <b-col md="6" lg="4" order-md="2">
                <b-card-img src="@/assets/headshots/jakko.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" lg="8" order-md="1">
                <b-card-body title="Jakko de Jong">
                  <b-card-text>Business Developer</b-card-text>
                  <b-card-text class="description">
                    {{ $t("team.jakko.desc") }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="12" class="mb-4">
          <b-card no-body>
            <b-row no-gutters align-v="center">
              <b-col md="6" lg="4">
                <b-card-img src="@/assets/headshots/mark1.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" lg="8">
                <b-card-body title="Mark Boer">
                  <b-card-text>AI Engineer</b-card-text>
                  <b-card-text class="description">
                    {{ $t("team.mark1.desc") }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="12" class="mb-4">
          <b-card no-body>
            <b-row no-gutters align-v="center">
              <b-col md="6" lg="4" order-md="2">
                <b-card-img src="@/assets/headshots/mark0.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" lg="8" order-md="1">
                <b-card-body title="Mark de Wijk">
                  <b-card-text>AI Engineer</b-card-text>
                  <b-card-text class="description">
                    {{ $t("team.mark0.desc") }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="12" class="mb-4">
          <b-card no-body>
            <b-row no-gutters align-v="center">
              <b-col md="6" lg="4">
                <b-card-img src="@/assets/headshots/dominique.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" lg="8">
                <b-card-body title="Dominique Cirkel">
                  <b-card-text>AI Specialist</b-card-text>
                  <b-card-text class="description">
                    {{ $t("team.do.desc") }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row align-h="around">
        <b-col cols="12" class="mb-4">
          <b-card no-body>
            <b-row no-gutters align-v="center">
              <b-col md="6" lg="4" order-md="2">
                <b-card-img src="@/assets/headshots/jordy.jpg" alt="Image" class="rounded-0"></b-card-img>
              </b-col>
              <b-col md="6" lg="8" order-md="1">
                <b-card-body title="Jordy de Lange">
                  <b-card-text>Data Scientist</b-card-text>
                  <b-card-text class="description">
                    {{ $t("team.jordy.desc") }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <Values />
  </div>
</template>

<script>
import Values from "./Values";

export default {
  name: "Team",
  components: {
    Values,
  },
};
</script>

<style scoped>
.card {
  border: 2px solid var(--theme-color-one-light);
}

.card-title {
  font-size: 1.1rem;
  font-weight: 900;
  text-align: center;
}

.card-text {
  text-align: center;
  color: #808080;
}

.description {
  text-align: left;
  font-size: 0.8rem;
}

.pink-fill {
  background-color: var(--theme-color-one-light);
  height: 30em;
  width: 100%;
}
</style>
