<template>
  <div id="app">
    <Navbar />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Gowun+Dodum&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
}

:root {
  --theme-color-one: #FF3E7C;
  --theme-color-one-light: rgba(255, 62, 124, 0.15);
  --theme-color-two: #14e3d3;
  --theme-color-three: #22223B;
  --theme-color-four: #4A4E69;
  --theme-color-five: #ffffff;
  font-size: calc(1.0em + 0.2vw);
}

#app {
  font-family: 'Gowun Dodum', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-color-four);
  height: 100%;
  background-color: var(--theme-color-five);
}

/* Confirmation model for contact form has to be styles globally. */
#confirmation {
  font-family: 'Gowun Dodum', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-color-four);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--theme-color-three);
}

html {
  background-color: var(--theme-color-five);
}

.b-container {
  background-color: var(--theme-color-five);
}

.component {
  padding-top: 4em;
  padding-bottom: 4em;
}

.background {
  box-shadow: inset 0 32px 32px -32px var(--theme-color-one-light);
}

.subscript {
  color: grey;
  font-size: 0.8em;
  margin-bottom: 0em;
}
</style>
