<template>
  <b-container class="component">
    <div class="roadmap-component">
      <div class="roadmap-container">
        <h1>Stappenplan</h1>
        <div class="title">
          <b-icon-caret-left class="icon" @click="decrement"></b-icon-caret-left>
          <h5>{{ currentItem }}. {{ currentTitle }}</h5>
          <b-icon-caret-right class="icon" :animation="animation" @click="increment"></b-icon-caret-right>
        </div>
        <div class="subtitle">
          <b-icon :icon="currentIcon" class="icon"></b-icon>
        </div>
        <p>{{ currentDesc }}</p>
      </div>
    </div>
  </b-container>
</template>

<script>
// the Roadmap doesn't reload very well. But it works okeish
export default {
  name: "Roadmap",
  components: {},
  data: function () {
    return {
      roadmap: {
        1: {
          title: this.$t("intake.1.title"),
          desc: this.$t("intake.1.desc"),
          icon: "cup",
        },
        2: {
          title: this.$t("intake.2.title"),
          desc: this.$t("intake.2.desc"),
          icon: "clipboard-data",
        },
        3: {
          title: this.$t("intake.3.title"),
          desc: this.$t("intake.3.desc"),
          icon: "eyeglasses",
        },
        4: {
          title: this.$t("intake.4.title"),
          desc: this.$t("intake.4.desc"),
          icon: "tools",
        },
        5: {
          title: this.$t("intake.5.title"),
          desc: this.$t("intake.5.desc"),
          icon: "life-preserver",
        },
      },
      currentItem: 1,
      animation: "fade",
    };
  },
  computed: {
    currentTitle: function () {
      return this.roadmap[this.currentItem]["title"];
    },
    currentDesc: function () {
      return this.roadmap[this.currentItem]["desc"];
    },
    currentIcon: function () {
      return this.roadmap[this.currentItem]["icon"];
    },
  },
  methods: {
    decrement: function () {
      if (this.currentItem > 1) {
        this.currentItem -= 1;
      }
    },
    increment: function () {
      if (this.currentItem < 5) {
        this.currentItem += 1;
        this.animation = "";
      }
    },
  },
};
</script>


<style scoped>
h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5em;
}

p {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subtitle {
  display: flex;
  justify-content: center;
}

.icon {
  height: 3em;
  width: 3em;
  color: var(--theme-color-one);
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.roadmap-component {
  display: flex;
  justify-content: center;
}

.roadmap-container {
  height: 20em;
}

@media (min-width: 540px) {
  .roadmap-container {
    width: 75%;
  }
}

h5 {
  margin-bottom: 0em;
}
</style>
