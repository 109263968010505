<template>
  <b-nav-item-dropdown :text="$t('navigation.language')">
    <b-dropdown-item v-for="(lang, i) in langs" :key="`Lang${i}`" @click="$i18n.locale = lang.value"
      :class="{ 'active-lang': $i18n.locale === lang.value }">
      {{ $t(lang.text) }}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: 'LangSelector',
  data() {
    return {
      langs: [
        { value: 'nl', text: 'languages.nl' },
        { value: 'en', text: 'languages.en' }
      ]
    }
  }
}
</script>

<style>
.active-lang .dropdown-item {
  background-color: transparent;
  color: var(--theme-color-one);
}
</style>
