<template>
    <div>
        <VueCompareImage :leftImage=leftImage :leftLabel=leftLabel :rightImage=rightImage :rightLabel=rightLabel
            class="slide-to-compare" />
        <p class="subscript">{{ $t("slide-to-compare.subscript") }}</p>
    </div>
</template>
<script>
import VueCompareImage from "vue-compare-image";

export default {
    name: "SlideToCompare",
    components: { VueCompareImage },
    methods: {},
    props: ["leftImage", "leftLabel", "rightImage", "rightLabel"]
}
</script>
<style>
.slide-to-compare {
    margin-bottom: 0.5rem;
    border: 2px solid var(--theme-color-one-light);
}

.slide-to-compare img {
    max-height: 100%;
}

.slide-to-compare .left-label {
    color: white;
    font-weight: bold;
}

.slide-to-compare .right-label {
    color: white;
    font-weight: bold;
}
</style>
