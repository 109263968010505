<template>
  <div class="background">
  <b-container class="component">
    <h1>{{ $t('navigation.contact') }}</h1>
    <b-row>
      <b-col cols="12" md="6">
        <h5>{{ $t('contact.details') }}</h5>
        <p>{{ $t('contact.email') }}: contact@spheer.ai</p>
        <p>{{ $t('contact.tel') }}: +316 23758739</p>
        <p>LinkedIn: <a href="https://www.linkedin.com/company/74480410" target="_blank">Spheer.ai</a></p>
        <p>KvK: 83632433</p>
      </b-col>
      <b-col cols="12" md="6">
        <h5>{{ $t('contact.post') }}</h5>
        <p>Westerhaven 13-6</p>
        <p>9718 AW, Groningen</p>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="component">
    <h1>{{ $t('contact.form') }}</h1>
    <p style="margin-bottom: 1em">
      {{ $t('contact.form') }}
    </p>
    <EmailSignup/>
  </b-container>
  </div>
</template>

<script>
import EmailSignup from './EmailSignup.vue'

export default {
    name: 'Contact',
    components: {
        EmailSignup
    }
}
</script>


<style scoped>
/* .map {
  width: 100%;
  height: 24em;
} */

p {
  margin-bottom: 0em;
}

h5 {
  margin-bottom: 0.5em;
  margin-top: 1em;
  font-weight: bold;
}
</style>
