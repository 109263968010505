<template>
  <div class="background">
    <b-container class="component">
      <b-jumbotron class="product-jumbotron">
        <template #header>{{ $t("maen.jumbotron.title") }}</template>
        <template #lead>
          <p v-html="$t('maen.jumbotron.lead')"></p>
        </template>
        <p>
          {{ $t("maen.jumbotron.text") }}        </p>
        <b-button variant="info" to="/contact" class="contact">
          {{ $t("maen.jumbotron.contact") }}
        </b-button>
      </b-jumbotron>
      <br>
      <b-row>
        <b-col>
          <h1>{{ $t("maen.maen") }}</h1>
          <p>
            {{ $t("maen.text1") }}</p>
          <p>
            {{ $t("maen.text2") }}
          </p>
          <p>
            {{ $t("maen.text3") }}
          </p>
        </b-col>
      </b-row>
      <brow>
        <Roadmap />
      </brow>
      <b-row>
        <b-col>
          <h1>{{ $t("maen.datasources.title") }}</h1>
          <p>
            {{ $t("maen.datasources.text") }}
          </p>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="12" md="4" class="mb-4" order="1" order-md="1">
          <h3>{{ $t("maen.sentinel2.title") }}</h3>
          <p>
            {{ $t("maen.sentinel2.text") }}
          </p>
        </b-col>
        <b-col cols="12" md="8" class="mb-4" order="2" order-md="2">
          <SlideToCompare :leftImage=luchtfoto leftLabel="Luchtfoto" :rightImage=s2 rightLabel="Sentinel 2" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4" class="mb-4" order="1" order-md="2">
          <h3>{{ $t("maen.sentinel1.title") }}</h3>
          <p>
            {{ $t("maen.sentinel1.text") }}
          </p>
        </b-col>
        <b-col cols="12" md="8" class="mb-4" order="2" order-md="1">
          <SlideToCompare :leftImage=luchtfoto leftLabel="Luchtfoto" :rightImage=s1 rightLabel="Sentinel 1" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import SlideToCompare from "./SlideToCompare.vue"
import Luchtfoto from "@/assets/luchtfoto_schier.jpg"
import S1 from "@/assets/s1_schier.jpg"
import S2 from "@/assets/s2_schier.jpg"
import Roadmap from '../home/Roadmap'


export default {
  name: "Maen",
  components: { Roadmap, SlideToCompare },
  methods: {},
  data: function () {
    return {
      luchtfoto: Luchtfoto,
      s1: S1,
      s2: S2
    }
  }
}
</script>
<style scoped>
.contact {
  font-size: 1.25rem;
  float: right;
  clear: both;
  /* Is margin right een goede manier om dit te doen @Mark? */
  margin-right: 5rem;
}
</style>
