<template>
  <b-container class="component">
    <h1>{{ $t('partners.clients') }}</h1>
    <b-row align-v="center" align-h="center">
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.amsterdam.nl/">
          <img src="@/assets/client_logos/amsterdam.png" alt="Gemeente Amsterdam" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://relay42.com/">
          <img src="@/assets/client_logos/relay42.png" alt="Relay42" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.fryslan.frl/">
          <img src="@/assets/client_logos/fryslan.jpg" alt="Provincie Fryslân" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://walterliving.com/">
          <img src="@/assets/client_logos/walter.svg" alt="Walter Living" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.provinciegroningen.nl/">
          <img src="@/assets/client_logos/groningen.svg" alt="Provincie Groningen" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.rotterdam.nl/">
          <img src="@/assets/client_logos/rotterdam.svg" alt="Gemeente Rotterdam" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.alliander.com/nl/">
          <img src="@/assets/client_logos/alliander.png" alt="Alliander" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://truston.nl/">
          <img src="@/assets/client_logos/truston.png" alt="Truston" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.bij12.nl/">
          <img src="@/assets/client_logos/bij12.png" alt="BIJ12" />
        </a>
      </b-col>
      <b-col cols="6" md="3" class="mb-4">
        <a href="https://www.brabant.nl/">
          <img src="@/assets/client_logos/noordbrabant.jpg" alt="Provincie Noord-Brabant" />
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Partners",
};
</script>


<style scoped>
h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5em;
}

img {
  width: 100%;
  /* border: 2px solid var(--theme-color-one-light); */
  /* border-radius: 0.25rem; */
}
</style>
