<template>
    <div>
        <GeneralHero />
        <!-- <Products /> -->
        <Qualities />
        <Roadmap />
        <Partners />
    </div>
</template>

<script>
import GeneralHero from './GeneralHero'
// import Products from './Products'
import Qualities from './Qualities'
import Partners from './Partners'

export default {
    name: 'Home',
    components: {
        GeneralHero,
        // Products,
        Qualities,
        // Roadmap,
        Partners
    }
}
</script>


<style scoped></style>
