<template>
  <div class="background">
    <b-container class="component">
      <b-row align-v="center">
        <b-col cols="12" xl="7" class="mb-4">
          <img src="@/assets/group_photo.jpg" />
        </b-col>
        <b-col cols="12" xl="5" class="mb-4 clearfix">
          <h1>Spheer.ai</h1>
          <p>
            {{ $t('main.intro') }}
          </p>
          <b-button variant="info" to="/contact" class="contact">
            {{ $t('contact.call2action') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col>
          <!-- main-text contains some HTML (a link), so it needs to be added as v-html -->
          <p class="intro" v-html="$t('main.main-text')">
          </p>
        </b-col>
      </b-row>
      <br>
      <b-jumbotron class="product-jumbotron" link>
        <template #header>{{ $t('main.jumbotron.header') }}</template>
        <p>
          {{ $t('main.jumbotron.text') }}
        </p>

        <p>{{ $t('main.jumbotron.subtext') }}</p>

        <b-button variant="outline-info" class="implementation-button" :to="{
          path: '/natuurmonitoring', hash: '#heidevergrassing'
        }">
          {{ $t('main.jumbotron.heather') }}
        </b-button>
        <b-button variant="outline-info" class="implementation-button" :to="{
          path: '/natuurmonitoring', hash: '#kweldermonitoring'
        }">
          {{ $t('main.jumbotron.saltmarsh') }}
        </b-button>
      </b-jumbotron>
      <br>
      <b-row align-h="center">
        <b-col>
          <p class="intro">
            {{ $t('main.other-text') }}
          </p>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
export default {
  name: "GeneralHero",
  components: {},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.implementation-button {
  font-size: 1.25rem;
  margin-top: 1.0rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  /* width: 100%; */
}

.implementation-button:hover {
  color: #17a2b8;
  background-color: transparent;
  border-color: #17a2b8;
}

.contact {
  font-size: 1.25rem;
  float: right;
  clear: both;
}

p {
  font-size: 1.25rem;
}

img {
  width: 100%;
  border: 2px solid var(--theme-color-one-light);
  border-radius: 0.25rem;
}

h1 {
  font-size: 3rem;
  font-weight: 900;
  color: var(--theme-color-one);
  letter-spacing: 0.2rem;
}

p {
  margin-top: 1em;
}

.clearfix::after {
  display: block;
  content: " ";
  clear: both;
}
</style>
