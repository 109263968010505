import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VueI18n from "vue-i18n";
import VueRouter from 'vue-router';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import nl from "./locales/nl.json";
import en from "./locales/en.json";

Vue.config.productionTip = false
Vue.use(VueI18n)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import Home from './components/home/Home'
import Team from './components/team/Team'
import Contact from './components/contact/Contact'
import Natuurmonitoring from './components/products/Natuurmonitoring'
import Maen from './components/products/Maen'

const routes = [
  { path: '/', component: Home },
  { path: '/team', component: Team },
  { path: "/contact", component: Contact },
  { path: "/natuurmonitoring", component: Natuurmonitoring },
  { path: "/remotesensing", component: Maen }
]

const router = new VueRouter({ routes });

// configure i18n
const i18n = new VueI18n({
  locale: "nl",
  messages: { nl, en },
});


new Vue({
  el: '#app',
  router,
  render: h => h(App),
  i18n,
})
