<template>
  <b-container>
    <b-navbar toggleable="lg" id="spheer-navbar">
      <b-navbar-brand to="/">
        <img id="spheer-logo" src="../assets/logo.png">
        Spheer.ai
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item to="/" exact-active-class="active">{{ $t('navigation.about') }}</b-nav-item>
          <b-nav-item-dropdown :text="$t('navigation.diensten')">
            <!-- TODO hoe pas ik dit aan -->
            <b-dropdown-item to="natuurmonitoring" exact-active-class="active">{{
              $t('navigation.natuur') }}</b-dropdown-item>
            <b-dropdown-item to="remotesensing" exact-active-class="active">{{
              $t('navigation.remotesensing') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="team" exact-active-class="active">{{ $t('navigation.team') }}</b-nav-item>
          <b-nav-item to="contact" exact-active-class="active">{{ $t('navigation.contact') }}</b-nav-item>
          <LangSelector />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import LangSelector from "./LangSelector.vue";

export default {
  name: 'Navbar',
  components: {
    LangSelector,
  },
}
</script>

<style>
/* We can't use scoped styles when styling Boostrap Vue components. */
#spheer-navbar .nav-link {
  color: var(--theme-color-three);
}

#spheer-navbar .active {
  color: var(--theme-color-one);
  background-color: transparent;
}

#spheer-navbar .dropdown-item:active {
  background-color: transparent;
  color: var(--theme-color-three);
}

#spheer-navbar .navbar-brand {
  color: var(--theme-color-one);
  letter-spacing: 0.2rem;
  font-weight: 900;
}

#spheer-logo {
  height: 2.5rem;
}
</style>
