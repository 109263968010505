<template>
  <div class="background">
    <b-container class="component">
      <b-row>
        <b-col cols="6" md="4" order="2">
          <h5>{{ $t('contact.details')}}</h5>
          <p><a href="mailto: contact@spheer.ai"><BIconEnvelope /> contact@spheer.ai</a></p>
          <p><a href="https://www.linkedin.com/company/74480410" target="_blank"> <BIconLinkedin /> Spheer.ai </a></p>
          <p><a href="https://github.com/Spheer-ai" target="_blank"> <BIconGithub /> Spheer.ai </a></p>
          <p><a href="tel: +316 23758739"><BIconTelephone /> +316 23758739</a></p>
        </b-col>
        <b-col cols="6" md="4" order="3">
          <h5>{{ $t('contact.post')}}</h5>
          <p>Westerhaven 13-6</p>
          <p>9718 AW, Groningen</p>
        </b-col>
        <b-col cols="12" md="4" order="1">
          <h5>Menu</h5>
          <p>
            <router-link @click.native="scrollToTop" to="/" class="inactive" exact-active-class="active">{{ $t('navigation.about')}}</router-link>
          </p>
          <p>
            <router-link @click.native="scrollToTop" to="/natuurmonitoring" class="inactive"
              exact-active-class="active">{{ $t('navigation.natuur')}}</router-link>
          </p>
          <p>
            <router-link @click.native="scrollToTop" to="/remotesensing" class="inactive"
              exact-active-class="active">{{ $t('navigation.remotesensing')}}</router-link>
          </p>
          <p>
            <router-link @click.native="scrollToTop" to="/team" class="inactive" exact-active-class="active">{{ $t('navigation.team')}}</router-link>
          </p>
          <p>
            <router-link @click.native="scrollToTop" to="/contact" class="inactive"
              exact-active-class="active">{{ $t('navigation.contact')}}</router-link>
          </p>
        </b-col>
        <b-col cols="12" md="3" order="6" order-md="4">
          <div class="copyright">
            <h5>© Spheer.ai</h5>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { BIconEnvelope, BIconLinkedin, BIconGithub, BIconTelephone } from 'bootstrap-vue'

export default {
  name: "Footer",
  components: {
    BIconEnvelope, BIconLinkedin, BIconGithub, BIconTelephone
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.background {
  box-shadow: inset 0 32px 32px -32px var(--theme-color-one-light);
}

.container {
  padding: 1em;
}

p {
  margin-bottom: 0em;
  font-size: 0.75rem;
}

h5 {
  margin-bottom: 0.5em;
  margin-top: 1em;
  font-weight: bold;
  font-size: 0.75rem;
}

.copyright {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.copyright>h5 {
  color: var(--theme-color-one);
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin-top: 2em;
}

.contact {
  margin-top: 1em;
  font-size: 1rem;
}

.inactive {
  color: var(--theme-color-three);
}

.active {
  color: var(--theme-color-one);
}

.active:hover {
  color: var(--theme-color-one);
}
</style>
